import React, { useRef } from 'react';
import './App.css';
import TerminalComponent from './components/TerminalComponent';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/draggable';

import logoOne from './assets/logo.svg';
import logoTwo from './assets/logo2.svg';

class App extends React.Component {
 
  constructor(props){
    super(props);
    this.state = {toggle: false};
    this.handleCLick = this.handleCLick.bind(this);
  }

  componentDidMount(){
    $(document).ready(function(){
      $('.shell').hide();
      $('.minimize').on('click', function(){minimize();});
      $('.enlarge').on('click', function(){maximize();});
      $('.intro').append('Missing image!');
    });
    
    $('.logo').click(function() {
      $('.logo').addClass('hide');
      $('.logo2').removeClass('hide');
      $('.intro').removeClass('hide');
    });
    $('.logo2').click(function() {
      $('.shell').removeClass('hide');
      $('.shell').show('fast');
      $('.intro').addClass('hide');
    });
    
    $('.close').click(function() {
      $('.shell' ).hide('fast');
    });
    
    $('.show').click(function() {
      $('.shell').removeClass('minimized');
      $('.content').removeClass('hide');
      $('.enlarge').addClass('hide');
      $('.shell').draggable('enable');
      $('.shell').resizable('enable');	
      $('.shell' ).show('fast');
    });
    
    function minimize(){
      $('.shell').addClass('minimized');
      $('.content' ).hide();
      $('.enlarge').removeClass('hide');
      $('.shell').resizable('disable');
    }
    
    function maximize(){
      $('.shell').removeClass('minimized');
      $('.content' ).show();
      $('.enlarge').addClass('hide');
      $('.shell').resizable('enable');
    }

    this.$node = $(this.refs.resizable);
    this.$node.resizable({
      minHeight: 108,
      minWidth: 250,
      containment: '.center'
    }).draggable({
      handle: '> .status-bar .title',
      containment: '.center'
    });
  }

handleCLick(){
  window.gtag("event", "clickLogo2", {
    'event_category': "clickLogo2",
    'event_label': "logo2",
  });

  this.setState({toggle: true});
}

handleCLickOne(){
  window.gtag("event", "clickLogo", {
    'event_category': "clickLogo",
    'event_label': "logo",
  });
}

  render() {
    return (
      <>
      <div className="container">
        <div className="center">

        <img src={logoOne} className="logo" onClick={this.handleCLickOne}/>
        <img src={logoTwo} className="logo2 hide" onClick={this.handleCLick}/>  
        <div className="intro hide"></div>
        <div className="shell custom dark shadow hide" ref="resizable">
          <div className="status-bar">
            <div className="buttons">
              <a className="close" title="Close">
                <i className="fa fa-times"></i>
              </a>
              <a className="minimize" title="Minimize">
                <i className="fa fa-minus"></i>
              </a>
              <a className="enlarge hide" title="Maximize">
                <i className="fa fa-plus"></i>
              </a>
            </div>
            <div className="title">user@root</div>
            </div>
            <div className="content">
                {this.state.toggle ? <TerminalComponent></TerminalComponent> : <></>}
            </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="row">
            <div className="column">
              © SANTONIAN Industries 2017
            </div>
            <div className="column">
              <a href="mailto:contact@santonianindustries.com?Subject=Contact">Contact</a>
            </div>
          </div>
        </div>
      </>
    )
  }
}


export default App;