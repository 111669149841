import '../App.css';
import React from 'react';
import Terminal from './Terminal';
import axios from 'axios';

class TerminalComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      command: '', 
      play: false, 
      currentFolder: '',
      currentFiles: '',
      initialLoad: false,
      audioFilePlaying: false,
      audioFileRef: null,
      currentStructure: [],
      greeting:''};
  }

  //audio = new Audio(`${process.env.PUBLIC_URL}/keyboard_press.mp3`);
  currentDate = new Date().toISOString().slice(0, 10);

  componentDidMount () {
    this.getGreeting();
    // this.audio.addEventListener('ended', () => this.setState({play: false}));
  }

  update(command){
    this.setState({
      command
    });

    if(!this.state.initialLoad)
    {
      this.setState({initialLoad: true});
      return;
    }

    /*
    if(this.state.play){
      this.audio.currentTime = 0;
    }
    this.audio.play();
    this.setState({play: true});
    */
  }

  exec(){
    this.terminal.exec(this.state.command);
    this.update('');
  }

  getGreeting = () => {
    return axios.get(`https://santonianindustries.com/backend/greetings`)
      .then(res => {
        this.setState({greeting: res.data});
        return res.data;
      });
  }

  getDiskInfo = (disk) => {
    return axios.get(`https://santonianindustries.com/backend/hdd_details/${disk}`)
      .then(res => {
        if(res.data.type !== "ERR"){
          this.setState({currentFolder: disk + '\/'});
        }
        else if(res.data.type === "ERR"){
          return res.data.message;
        }
        return this.getDiskFileList(res.data.message);
      });
  }

  getDiskList = () => {
    return axios.get(`https://santonianindustries.com/backend/hdd`)
      .then(res => {
        let diskList = "";
        this.setState({currentStructure: []});
        this.setState({currentStructure: res.data})
        res.data.forEach(element => {
          diskList += element + "\n";
        });
        return diskList;
      });
  }

  getDiskFileList = (fileID) => {
    return axios.get(`https://santonianindustries.com/backend/file/${fileID}`)
    .then(res => {
      let files = "";
      
      // Check if not object instead
      if(res.data === "NO ITEM WITH THAT NAME")
        return res.data;

      this.setState({currentStructure: []});
      this.setState({currentStructure: res.data})

      res.data.forEach(element => {
        files += element + "\n";
      });

      this.setState({currentFiles: files});
      return files;
    });
  }

  getFileInformation = (fileName) => {
    return axios.get(`https://santonianindustries.com/backend/readFile/${fileName}`)
    .then(res => {
      if(res.data.includes(".mp3")){
        const soundFile = new Audio(res.data);
        soundFile.play();
        this.setState({audioFileRef: soundFile});
        this.setState({audioFilePlaying: true});
        soundFile.addEventListener('ended', () => this.setState({audioFilePlaying: false}));
        return `PLAYING SOUND FILE. PRESS ANY KEY TO CONTINUE\n\n`;
      }
      if(res.data.includes("NO ITEM WITH THAT NAME")){
        return res.data;
      }
      return this.readLogPrefix(fileName) + res.data + this.readLogSuffix();
    });
  }

  checkCommand = (command) => {

    window.gtag("event", "commands", {
      'event_category': "commands",
      'event_label': command,
    });

    if(command === "COMMANDS")
      {
        return Promise.resolve(this.terminalCommands());
      }

    if(command === "LIST"){
      if (this.state.currentFiles !== ''){
        return Promise.resolve(this.state.currentFiles);
      }
      return this.getDiskList();
    }
    
    if(command.includes("READ")){
      let val = command.replace('READ ', ''); 
      val = val.split('.')[0];
      if(!this.state.currentFiles.includes(val)){
        //return Promise.resolve("[[b;#ff0000;]Enter a file name.]\n");
        return Promise.resolve(`ReadLog v1.12\n\nNo Log found with filename '${val}'\n`);
      }
      return this.getFileInformation(val);
    }

    if(command.includes("PLAY")){
      let val = command.replace('PLAY ', ''); 
      val = val.split('.')[0];
      
      if(!command.includes(".AUD")) {
        return Promise.resolve(`Unable to play '${val}'\n`);
      }

      if(!this.state.currentFiles.includes(val)){
        //return Promise.resolve("[[b;#ff0000;]Enter a file name.]\n");
        return Promise.resolve(`ReadLog v1.12\n\nNo Log found with filename '${val}'\n`);
      }
      
      return this.getFileInformation(val);
    }

    // Should return data values on that drive
    if(command.includes("CD")){
      if((command.includes("CD ..") || command.includes("CD..")) && this.state.currentFolder.length !== 0){
        this.setState({currentFolder: '', currentFiles : ''});
        this.setState({currentStructure: []});
        return Promise.resolve("");
      } else if((command.includes("CD ..") || command.includes("CD..")) && this.state.currentFolder.length === 0){
        return Promise.resolve("ROOT FOLDER");
      }
      let string = command.replace('CD ', '');
      if(string === "CD"){
        string = string.replace('CD', '');
      }
      if(string.length === 0){
        return Promise.resolve("[[b;#ff0000;]Enter a folder name.]\n");
      }
      let fileList = this.getDiskInfo(string);
      return fileList;
    }

    if(command === "HELP"){
      return Promise.resolve(this.terminalHelp());
    }

    if(command.trim() === ""){
      return Promise.resolve("");
    }

    return Promise.resolve(`'${command}' is not recognized as a command.\n`);
  }

  readLogPrefix = (log) => {
    return "\nReadLog1.12\n\n"+
    "Opening log file "+ log +".LOG: DONE!\n\n"+
    "##########################################\n\n";
  }

  readLogSuffix = () => {
    return "\n##########################################\n\n" +
    "End of file, Exiting ReadLog 1.12>\n";
  }
 
  terminalGreeting = () => {
    return this.state.greeting;
  }

  terminalHelp = () => {
    return "\nUse the keyboard to write commands.\n"+
    "Use [Enter/Return] to execute commands.\n"+
    "Use [Backspace] to erase a character.\n"+
    "Use [TAB] to autocomplete your command.\n"+
    "Use [Up Arrow] to traverse your earlier executed commands.\n";
  }

  terminalCommands = () => {
    return "\nLIST\n" +
    "COMMANDS\n" +
    "HELP\n" +
    "READ\n" +
    "CD\n";
  }

  setPrompt = () => {
    return '\/\/Root\/' + this.state.currentFolder;
  }

  getAutoCompleation = () => {
    const compleationArr = ['COMMANDS', 'LIST', 'CD', 'READ', 'HELP'];
    const newArr = compleationArr.concat(this.state.currentStructure);
    return newArr;
  }  
  render() {
    let command = this.state.command;
    let animation = false;
    const autocomplete = this.getAutoCompleation();

    if(this.state.greeting.length === 0)
      return null;

    return (
      React.createElement("div", {style: {height: "100%"}}, null, 
      React.createElement(Terminal, {
        ref: node => this.node = node,
        interpreter: (command, term) => {
          if(command.toLowerCase() === "list"){
            let i = 0;
            animation = true;
            let value = "";
            let dots = "";
            this.checkCommand(command.toUpperCase()).then(e => {
              value = e;
            })
            let timer = setInterval(() => {
              if(i < 100){
                i++;
                if(i % 2){
                  dots += ".";
                }           
                term.set_prompt(`${i}%${dots}\n`);
              }
              else {
                clearInterval(timer);
                term.echo(`${i}%\n`);
                term.echo(value);
                term.set_prompt(this.setPrompt());            
                term.resume();
                animation = false;
              }
            }, 25);
            
          } else {
            term.pause();
            this.checkCommand(command.toUpperCase()).then(e => {
              term.echo(e)

              if(this.state.audioFilePlaying){
                term.resume();
                term.set_prompt("");
                let timer = setInterval(() => {
                  if(!this.state.audioFilePlaying){
                    clearInterval(timer);            
                    term.set_prompt(this.setPrompt());
                  }
                }, 50);
              } else {
                term.resume();
                term.set_prompt(this.setPrompt());
              }

            })
            .catch(() => {
              term.resume();
            }); 
          }
        },
        keydown: () => { 
          if(animation) { return false } 
          if(this.state.audioFilePlaying) {
            this.state.audioFileRef.pause();
            this.state.audioFileRef.currentTime = 0;
            this.setState({audioFileRef: null});
            this.setState({audioFilePlaying: false});
          }
        },
        outputLimit: -1,
        command: command,
        prompt: this.setPrompt(),
        onInit: term => {this.terminal = term;},
        onCommandChange: command => this.update(command),
        greetings: this.terminalGreeting(),
        onBlur: () => { return false; },
        checkArity: false,
        autocompleteMenu: true,
        completion: function(string, callback){
          callback(autocomplete);
        }
        }))
      )
  }
}


export default TerminalComponent; 